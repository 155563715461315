import React from "react";

export default function SpotifyCard({ url, tstyle }) {
  return (
    <div className={tstyle}>
      <iframe
        style={{ borderRadius: "12px" }}
        src={url}
        title={url}
        width="100%"
        height="152px"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; FullScreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
}
