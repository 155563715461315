import { AnimatePresence, motion } from "framer-motion";

import React, { useState } from "react";
import "./Loader.css";
import App from "./components/App";

const pageVariants = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  duration: 1,
};

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

export default function Loader() {
  const [loading, setLoading] = useState(true);

  const click = () => {
    setLoading(false);
  };

  const getContent = () => {
    if (loading) {
      return (
        <AnimatePresence>
          <motion.div
            style={style}
            exit={{ opacity: 0, duration: 1 }}
            variants={pageVariants}
            animate="in"
            initial="out"
          >
            <img
              src="https://www.riedmann.rocks/title.jpg"
              onLoad={click}
              alt="hidden"
              style={{display:"none"}}
            />
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </motion.div>
        </AnimatePresence>
      );
    } else
      return (
        <motion.div
          transition={pageTransition}
          variants={pageVariants}
          animate="in"
          initial="out"
        >
          <App />
        </motion.div>
      );
  };

  return getContent();
}
