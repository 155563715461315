import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Loader from "./Loader";
import {createRoot} from 'react-dom/client';
import {StrictMode} from 'react';

const routing = (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Loader/>} />
   
    </Routes>
  </BrowserRouter>
);


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    {routing}
  </StrictMode>,
);

